.input {
  $root: &;
  display: block;
  position: relative;

  &__wrapper {
    width: 100%;
    height: $inputFieldHeightMobile;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    border: 1px solid $whiteColor;
    transition: border .2s, box-shadow .2s;
    background: $inputBackgroundColor;
    position: relative;
    border-radius: 1.8px;

    @media (min-width: $screen-tablet-portrait) {
      height: $inputFieldHeightDesktop;
    }

    &--textarea {
      height: 110px;

      .input__label + & {
        padding: 20px 0 3px 12px;
      }

      .input__label--as-placeholder + &,
      .input__label--hide-label + & {
        padding: 7px 11px;
      }
    }

    &:hover,
    &--focus {
      border: 1px solid $inputSuccessBorderColor;
    }

    &--focus {
      box-shadow: 0 0 10px 0 rgb(0 0 0 / .25);
      border: 1px solid $inputFocusBorderColor;
      background: $inputFocusBackgroundColor;
    }

    &--error {
      border: 1px solid $inputErrorBorderColor;
      background: $inputErrorBackgroundColor;

      &:hover {
        border: 1px solid darken($inputErrorBorderColor, 15);
      }
    }

    &--success,
    &--valid {
      background: $inputSuccessBackgroundColor;
    }

    &--hidden {
      border: 0 none;
      background: transparent;
      display: none;

      &:hover {
        border: 0 none;
      }
    }

    &--valid {
      @include iconFont('checkSmall', after) {
        position: absolute;
        color: $inputIconValidColor;
        right: 15px;
        top: 12px;
        font-size: 19px;

        .input__wrapper--textarea & {
          top: 22px;
        }
      }

      & > .input__field {
        padding-right: 40px !important;
      }
    }

    &--disabled {
      background: $backgroundGrayColor;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $borderDefaultColor;
      }
    }
  }

  &__field {
    width: 100%;
    height: 100%;
    padding: 3px 15px;
    font-size: $inputFieldFontSizeMobile;
    font-weight: $fontWeightRegular;
    color: $inputFieldColor;
    border: none;
    background: none;
    flex: 1;
    resize: none;
    border-radius: 4px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $inputFieldFontSizeDesktop;
    }

    .input__label + .input__wrapper & {
      padding: 13px 15px 3px;
    }

    .input__label--as-placeholder + .input__wrapper & {
      padding: 7px 15px;
    }

    .input__label + .input__wrapper &--textarea {
      padding: 0 15px 0 0;
    }

    &:focus {
      outline: none;
    }

    &[disabled],
    &--disabled {
      opacity: .55;
      cursor: not-allowed;
    }

    &::placeholder {
      font-size: $inputFieldFontSizeMobile;
      color: $grayColor;
      opacity: 1;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $inputFieldFontSizeDesktop;
      }
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
  }

  &__button {
    height: 100%;
    flex: 0 0 auto;
    border: none;
    background: $brandPrimaryColor;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding: 0 40px 0 5px;
    color: $textLightColor;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include iconFont(arrowRight, after) {
      font-size: $fontSizeSmall;
    }

    &--icon {
      &_search {
        @include iconFont(search, after) {
          font-size: $fontSizeMedium;
        }
      }

      &_mail {
        @include iconFont(mail, after) {
          font-size: $fontSizeMedium;
        }
      }
    }
  }

  &__button-icon {
    position: relative;
    z-index: 2;
    font-size: 18px;
    color: $textLightColor;
  }

  &__button-text {
    position: relative;
    z-index: 2;
    font-size: $baseFontSize;
    font-weight: $fontWeightRegular;
    color: $textLightColor;
    display: block;
    padding-right: 5px;
  }

  &__error {
    margin-top: 8px;
    position: relative;
    z-index: 5;
  }

  &__label {
    font-size: 10px;
    color: $inputLabelColor;
    display: block;
    margin: 0;
    max-height: 50px;
    overflow: hidden;
    transform: translate(0, 0);
    transition: transform .15s ease-out, opacity .05s ease-out;
    opacity: 1;
    position: absolute;
    z-index: 10;
    top: 5px;
    left: 17px;
    line-height: 1.3;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    width: calc(100% - 50px);

    @media (min-width: $screen-tablet-portrait) {
      font-size: 10px;
    }

    &--only-mobile {
      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }

    &--hide-label {
      display: none;
    }

    &--as-placeholder {
      pointer-events: none;
      opacity: 0;
      transform: translate(3px, 8px);
      padding-bottom: 0;
      font-size: 14px;
    }

    &.headline,
    &--static {
      position: relative;
      top: auto;
      left: auto;
      font-size: $inputFieldFontSizeMobile;
      color: $inputFieldColor;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $inputFieldFontSizeDesktop;
      }
    }
  }

  &__hint {
    display: block;
    margin-top: 5px;
    font-size: $fontSizeSmall;
    color: $textGrayColor;

    a {
      color: $textGrayColor;
    }
  }
}
