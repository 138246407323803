.select {
  $root: &;
  position: relative;
  font-size: 16px;

  &--active {
    z-index: $indexFlyout + 3;
  }

  &--no-label {
    #{$root}__selected-value {
      padding-top: 0;
    }
  }

  &__label {
    display: block;
    position: absolute;
    top: 8px;
    left: 17px;
    z-index: $indexFlyout + 1;
    transition: transform .15s ease-out;
    font-size: 10px;
    color: $grayColor;

    &--only-mobile {
      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }

    &--as-placeholder {
      pointer-events: none;
      transform: translate(0, 9px);
      padding-bottom: 0;
      font-size: 16px;

      @media (min-width: $screen-tablet-portrait) {
        transform: translate(0, 7px);
      }
    }
  }

  &__wrapper {
    width: 100%;
    height: $inputFieldHeightMobile;
    border: 1px solid $inputBorderColor;
    background: $inputBackgroundColor;
    position: relative;
    z-index: 0;
    transition: border .2s, box-shadow .2s;
    cursor: pointer;
    border-radius: $selectBorderRadius;

    @media (min-width: $screen-tablet-portrait) {
      height: $inputFieldHeightDesktop;
    }

    @include iconFont(arrowDownBold) {
      color: $selectIconColor;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-45%);
      z-index: 0;
      font-size: 16px;
    }

    #{$root}--active &,
    &:hover {
      border-color: $inputFocusBorderColor;
    }

    &--error {
      border-color: $errorColor !important;
    }

    &--success {
      border-color: $inputSuccessBorderColor !important;
    }

    &--disabled {
      background: $backgroundGrayColor;
      cursor: not-allowed;

      &:hover {
        border: 1px solid $borderDefaultColor;
      }

      &::before {
        display: none;
      }
    }

    &--active {
      z-index: $indexFlyout;
      border-color: $inputFocusBorderColor;

      &::before {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__field {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &--hidden {
      @include visually-hidden();
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  &__error {
    @include fontSize($baseFontSize);
    color: $textErrorColor;
    display: block;
    margin-top: 8px;
  }

  .input__error {
    margin-top: 0;
  }

  &__custom-dropdown {
    position: absolute;
    top: 100%;
    width: calc(100% + 1.8px);
    left: -.9px;
    overflow: hidden;
    max-height: 300px;
    background: $inputBackgroundColor;
    z-index: 11;

    &--expand-top {
      top: auto;
      bottom: 100%;
    }

    &--visible {
      border: 1px solid $inputFocusBorderColor;
      overflow-y: auto;
    }
  }

  &__selected-value,
  &__custom-option {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 8px 8px 12px;
    position: relative;

    &--align-right {
      justify-content: flex-end;
    }
  }

  &__selected-value {
    height: 100%;
    padding: 13px 35px 0 15px;
  }

  &__custom-option {
    height: 100%;
    border-bottom: 1px solid $grayColor;
    background: $inputBackgroundColor;
    min-height: 28px;
    font-size: 16px;

    &--selected {
      font-weight: $fontWeightSemiBold;
      color: $terracottaColor;
    }

    &:hover {
      @media (min-width: $screen-laptop) {
        background: $terracottaColor;
        color: $whiteColor;
      }
    }
  }

  &__selected-value-image,
  &__option-value-image {
    max-width: 26px;
    display: block;
    height: auto;
    margin: 0 12px -2px 0;
  }

  // usage outside of forms
  &--formless {
    margin: 0 -8px;

    #{$root}__wrapper {
      border: none;
      background: transparent;
      padding-right: 16px;
      border-radius: 0;

      @media (min-width: $screen-tablet-landscape) {
        padding-right: 32px;
      }

      #{$root}__selected-value {
        padding: 0 24px;
      }

      &--active.select__wrapper--custom {
        background: $sandHoverColor;

        &::before {
          z-index: 1;
        }

        #{$root}__selected-value {
          z-index: 1;
        }
      }

      &--disabled {
        padding: 0;
        margin-right: -8px;
      }
    }

    #{$root}__custom-dropdown {
      border: 0;
      top: 40px;
      left: 0;
      width: 100%;
      box-shadow: 0 10px 10px 0 rgb(50 50 45 / .2);
    }

    #{$root}__custom-options-wrapper {
      background: $sandHoverColor;
      padding: 8px 0;
    }

    #{$root}__custom-option {
      background: transparent;
      border: 0;
      padding: 4px 24px;

      &:hover {
        color: $whiteColor;
        background: $blackColor;
      }
    }
  }

  &--hide-placeholder {
    #{$root}__label {
      display: none;
    }

    #{$root}__selected-value {
      padding: $selectValuePadding;
    }
  }
}
