@import '../../../bps/components/datePicker/datePicker.scss';

.date-picker {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / .25);
  border: 1px solid $inputFocusBorderColor;
  border-radius: 4px;
  background: $whiteColor;
  font-size: $fontSizeXSmall;
  color: $textBaseColor;
  margin-top: -1px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: $fontSizeSmall;
  }

  &__select {
    color: $textBaseColor;
  }

  &__change-select-button {
    &::before {
      color: $whiteColor;
    }

    &--disabled {
      &::before {
        color: $sandMediumColor;
      }
    }
  }

  .button {
    @include switchButtonStyle('secondary>primary');
  }
}
