@import '../../../bps/components/passwordInput/passwordInput.scss';

.password-input {
  &__toggle-icon {
    color: $grayColor;
    top: 0;
    right: 5px;
    font-size: 20px;
  }

  .input {
    &__wrapper {
      .input__field {
        padding-right: 34px;
        margin-right: 0;
      }
    }
  }
}
