.input-warning {
  display: block;
  line-height: 1.4;
  color: $textWarningColor;
  font-size: 10px;
  padding: 2px 0 0 2px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 14px;
    padding: 7px 0 0 2px;
  }

  a {
    color: $textWarningColor;
  }
}
