.radio-input {
  $root: &;
  position: relative;
  width: 100%;
  cursor: pointer;
  margin: 0;
  display: block;

  &--disabled {
    opacity: .5;
    cursor: not-allowed;
    user-select: none;
  }

  &__wrapper {
    position: relative;
    display: block;
    padding-left: 25px;
  }

  &__row {
    padding: 20px 0 0;

    &--default-padding {
      padding: 20px 0 0 !important;
    }
  }

  &__input {
    visibility: hidden;
    height: 0;
    width: 0;
    z-index: -1;
    position: absolute;

    &:checked {
      + #{$root}__radio {
        &::before {
          content: '';
          border-radius: 50%;
          background: $inputRadioCheckedColor;
          display: block;
          width: 10px;
          height: 10px;
        }
      }

      ~ #{$root}__label {
        color: $inputRadioCheckedColor;
      }

      ~ #{$root}__thumb {
        border-color: $textDarkestColor;
      }

      ~ #{$root}__box {
        color: $textDarkestColor;
        border-color: $textDarkestColor;
      }
    }

    &:disabled {
      + #{$root}__radio,
      ~ #{$root}__label,
      ~ #{$root}__box,
      + #{$root}__thumb {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  &__radio {
    height: 20px;
    width: 20px;
    border: 1px solid $inputBorderColor;
    background: $inputBackgroundColor;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $screen-tablet-portrait) {
      top: 2px;
    }

    .form__radio-line & {
      top: -2px;
    }
  }

  &__label {
    color: $grayDarkColor;
    line-height: 1.3;
    font-size: 16px;
    font-weight: $fontWeightRegular;
    padding: 1px 15px 0 28px;
    display: inline-block;
    position: relative;
  }

  &__thumb {
    display: inline-block;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 15%;
    padding: 2px;
    overflow: hidden;
  }

  &__box {
    position: relative;
    height: 40px;
    width: auto;
    min-width: 40px;
    text-align: center;
    padding: 0 5px;
    border: 2px solid $borderLightColor;
    font-weight: $fontWeightSemiBold;
    font-size: 10px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $textDarkestColor;
      border-color: $textDarkestColor;

      #{$root}__input:disabled ~ &,
      #{$root}--disabled & {
        color: $textDarkestColor;
        opacity: .5;
        border-color: $borderLightColor;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 0;
      left: 0;
      border: 2px solid $borderWhiteColor;
    }
  }

  &__info {
    vertical-align: baseline;
    position: relative;
    top: -2px;
  }

  &__description {
    display: none;
    font-size: 16px;
    padding-left: 24px;
    margin-top: 4px;

    @media (min-width: $screen-tablet-portrait) {
      padding-left: 32px;
    }

    #{$root}--selected & {
      display: block;
    }
  }

  &__sprite-icon {
    & > [class^='sprite-'] {
      display: block;
      position: absolute;
      left: 32px;
      top: -8px;
    }
  }

  &__sprite-text {
    padding-left: 64px;
    display: inline-block;
  }

  &__sprite-text-sub {
    color: $grayColor;
    font-weight: $fontWeightRegular;
  }

  .radio-input--inline & {
    width: auto;
    display: inline-block;
    margin: 0 0 10px;
  }

  &--inline {
    margin: 0 0 -10px;

    #{$root}__label {
      @media (max-width: $screen-mobile-sm) {
        font-size: 14px;
      }
    }
  }
}
