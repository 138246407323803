.user-shortcut {
  position: relative;
  margin-right: -8px;
  margin-left: 18px;

  @media (min-width: $screen-desktop-sm) {
    margin-left: 12px;
  }

  &__link {
    transition: color .2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    text-align: center;
    font-style: normal;
    position: relative;
    color: $headerIconColor;
    background: $headerIconBackgroundColor;
    padding: 12px 8px;
    min-width: 25px;
    max-width: 124px;

    @media (min-width: $screen-desktop-sm) {
      padding: 8px;
    }

    &::before {
      font-size: 22px;
      color: inherit;
    }

    &--login {
      @include iconFont(user);
    }

    &--cart {
      @include iconFont(cart);
    }

    &:hover {
      color: $sandHoverColor;
    }

    &:active {
      color: $sandActiveColor;
    }

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        color: $sandColor;

        &:hover {
          color: $sandHoverColor;
        }

        &:active {
          color: $sandActiveColor;
        }
      }
    }
  }

  &__notification-badge-wrapper {
    position: absolute;
    margin: auto;
    border-radius: 20px;
    top: auto;
    left: -1px;
    bottom: 4px;

    @media (min-width: $screen-desktop-sm) {
      top: auto;
      left: 2px;
      bottom: -1px;
    }
  }

  &__notification-badge {
    background: $whiteColor;
    color: $terracottaColor;
    position: relative;
    font-size: 12px;
    min-width: 18px;
    height: 18px;
    line-height: 17px;
    border-radius: 20px;
    text-align: center;
    padding: 0 6px;
    display: none;

    &--active {
      display: block;
    }

    &--refer-a-friend {
      display: inline-block;
      background: $successColor;
      color: $whiteColor;
      font-weight: normal;
      top: 0;
      left: 8px;
      box-shadow: none;
      padding: 0 7px 0 6px;
    }

    &--update {
      animation: pulse;
      animation-duration: 2s;

      &.header__user-shortcut--wishlist {
        animation: none;
      }
    }
  }
}
