.vote-input {
  $root: &;
  display: inline-block;
  align-items: center;
  margin: 0 5px;

  &--selected {
    #{$root}__label {
      color: $grayColor;
    }
  }

  &__inputs {
    display: flex;
    flex-shrink: 1;
  }

  &__radio {
    display: none;
  }

  &__label {
    font-size: 20px;
    cursor: pointer;
    position: relative;
    width: 35px;
    height: 35px;
    transition: color .3s;
    color: $goldDarkColor;

    &::before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--positive {
      @include iconFont(checked);

      &#{$root}__label--checked {
        color: $successColor;
      }
    }

    &--negative {
      @include iconFont(plus) {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      margin-left: -1px;

      &#{$root}__label--checked {
        color: $textErrorColor;
      }
    }
  }

  &__error {
    width: 100%;
  }
}
