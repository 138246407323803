.checkbox {
  $root: &;
  cursor: pointer;

  &:hover {
    #{$root}__label {
      color: $checkboxLabelColor;
    }
  }

  &__wrapper {
    padding-left: 28px;
    position: relative;
    display: inline-block;
    min-height: 23px;
  }

  &__input {
    display: none;

    &:checked {
      + #{$root}__box {
        border-color: $checkboxCheckedBorderColor;
        background: $checkboxCheckedBorderColor;

        @include iconFont(checkSmall) {
          font-size: 10px;
          color: $checkboxCheckedColor;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        + #{$root}__label {
          color: $checkboxLabelColor;
        }
      }
    }
  }

  &__box {
    display: block;
    width: $checkboxWidth;
    height: $checkboxWidth;
    border: $checkboxBorder;
    position: absolute;
    left: 0;
    top: 2px;
    background: $checkboxBackground;
    border-radius: $checkboxBorderRadius;

    &--disabled {
      cursor: default;
      background: $grayColor !important;
      border-color: $grayColor !important;
    }

    #{$root}--error & {
      border-color: $errorColor;
    }
  }

  &__label {
    font-size: $checkboxLabelFontSizeMobile;
    line-height: $checkboxLabelLineHeight;
    color: $checkboxLabelColor;
    display: inline-block;
    margin: 3px 0 0 3px;

    a {
      color: $checkboxLabelColor;
    }

    &--disabled {
      color: $grayColor !important;
    }
  }

  &--disabled {
    cursor: not-allowed;
  }
}
