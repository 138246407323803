.input-error {
  display: block;
  line-height: 1.1;
  color: $textErrorColor;
  font-weight: $fontWeightRegular;
  font-size: 16px;
  padding: 2px 0 0 2px;

  @media (min-width: $screen-tablet-portrait) {
    padding: 7px 0 0 2px;
  }

  a {
    color: $textErrorColor !important;
  }

  &--animation {
    &-enter-active,
    &-leave-active {
      transition: transform .15s, opacity .15s;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateY(-8px);
    }
  }
}
