@use "sass:math";
$columnCount: 12;

.form {
  width: 100%;

  // group and rows with cols
  &__group {
    margin-bottom: 8px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 16px;
    }

    &:last-child:not(.form__group__inner-form) { // stylelint-disable-line
      margin-bottom: 0;
    }

    &--action-bar {
      margin-top: 25px;
    }

    &--row {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      margin-left: -10px;
      margin-right: -10px;
      padding: 0 8px;

      @media (min-width: $screen-mobile-large) {
        margin-left: -16px;
        margin-right: -16px;
      }
    }

    &--row-wrapper {
      width: 100%;
    }

    &--hide {
      display: none;
    }

    &--hidden {
      visibility: hidden;
      position: absolute;
    }

    &--inline {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-left: -24px;
      margin-right: -24px;

      & > * {
        margin: 0 24px;
      }
    }

    &--inline-strech {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      margin: 24px 0 0;
    }

    &__inner-form {
      margin: -16px 0 16px;

      @media (min-width: $screen-tablet-portrait) {
        margin: -8px 0 40px 32px;
      }
    }

    &__inner-form-text {
      padding: 24px 0 0;
    }

    &--row-button {
      margin: 16px 0;

      @media (min-width: $screen-tablet-portrait) {
        margin: 24px 0 16px;
      }
    }
  }

  @for $i from 1 through $columnCount {
    &__col-#{$i} {
      padding: 8px;
      width: 100%;

      @media (min-width: $screen-mobile-landscape) {
        width: calc(100% / #{math.div($columnCount, $i)});
      }

      // for use in checkout sidepanel
      // force all columns to be 100% width
      .form-in-column & {
        width: 100%;
      }
    }
  }

  &__row {
    width: 100%;
  }

  &__col {
    padding: 8px;
    width: 100%;
  }

  &__col--text {
    padding: 16px;
  }

  &__col--sub-padding {
    padding: 0 0 24px 24px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 0 24px 24px;
    }
  }

  &__col--relative {
    position: relative;
  }

  &__headline {
    font-size: $formHeadlineFontSizeMobile;
    font-family: $fontHeadlineFamily;
    margin: $formHeadlineMarginMobile;
    font-weight: $formHeadlineFontWheight;
    color: $formHeadlineColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $formHeadlineFontSizeDesktop;
      margin: $formHeadlineMarginDesktop;
    }
  }

  &__radio-sub-element {
    margin: -8px 0 24px;

    @media (min-width: $screen-tablet-portrait) {
      margin: -8px 0 24px 32px;
    }
  }

  &__link {
    display: inline-block;
    color: $blackColor;
    text-decoration: underline;
    font-size: 16px;

    &:visited {
      color: $blackColor;
    }

    body:not(.is_touch) & {
      &:hover {
        color: $blackHoverColor;
      }
    }

    &:active {
      color: $blackActiveColor;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 120%;
    margin: 0;

    a {
      color: inherit;
    }
  }

  &__message {
    margin: 20px 0;
    line-height: 1.3;

    &__text {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--error {
      color: $errorColor;

      a {
        color: $errorColor !important;
      }
    }

    &--warning {
      color: $warningColor;
    }

    &--success {
      color: $successColor;
    }

    &--info {
      color: $infoColor;
    }

    &--small-margin {
      margin: 8px 0 0;
    }
  }

  &__field-label {
    margin-bottom: 8px;
  }

  &__submit-loader {
    padding: 20px;
    margin: 24px auto;

    @media (min-width: $screen-mobile-landscape) {
      margin: 0;
    }
  }

  &__cancel-btn {
    color: $brownMediumColor;
    text-decoration: underline;
    align-self: center;
    margin: 0;
    transition: color .2s;
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    height: $buttonPrimaryHeightDesktop;
    justify-content: center;
    align-items: center;

    &:hover {
      color: $brownMediumHoverColor;
    }

    &:active {
      color: $brownMediumActiveColor;
    }
  }

  &__element-container {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    padding: 8px 0;
  }

  &__element {
    padding: 8px;
  }

  &__info-icon-text {
    font-size: $fontSizeXXSmall;
    margin: 16px 0;
  }

  &__info-icon {
    width: 16px;
    height: 16px;
    background: $grayLighterColor;
    border-radius: 100%;
    display: inline-block;
    text-decoration: none;
    margin: -1px 1px 0 0;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1;

    @include iconFont(infoPopup) {
      font-size: 16px;
      color: $grayDarkColor;
    }
  }

  &__mandatory-text {
    font-size: 12px;
    color: $brownMediumHoverColor;
    margin: 0 0 8px;
    width: 100%;
    line-height: 1.2;

    a {
      color: inherit;
    }
  }

  &__mandatory-text-block {
    font-size: $fontSizeXXSmall;
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: 0;
    }
  }

  &__option-area {
    background: $whiteColor;
    border-radius: 4px;
    padding: 16px 20px;
    border: 2px solid transparent;
    box-shadow: 0 0 0 rgb(0 0 0 / .5);
    transition: border .2s ease-in-out, box-shadow .2s ease-in-out;

    & + & {
      margin-top: 8px;
    }
  }

  &__option-area-input {
    color: red;

    &.radio-input {
      float: none;
    }
  }

  &__edit-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 1;
    font-size: $fontSizeSmall;
    text-decoration: underline;
  }
}
